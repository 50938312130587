import '@atlaskit/css-reset';

import { Grid, GridColumn } from '@atlaskit/page';
import React, { Component } from 'react';

import logo from '../images/hindsightLogo.png'
import styles from './footer.module.css'

class Footer extends Component {

  componentDidMount() {}
  
  render() {  
    return (
        <div className={styles.background}>
          <Grid>
            <GridColumn medium={3} />
            <GridColumn medium={6}>
              <a href="www.hindsightsoftware.com" target="_blank"><img src={logo} alt="Logo" className={styles.footerLogo}/></a>
              <p className={styles.copyRight}>© 2020 Hindsight Software Ltd. All rights reserved.</p>
            </GridColumn>
           <GridColumn medium={3} />
          </Grid>
        </div>
    );
  }
}

export default Footer;

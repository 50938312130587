import '@atlaskit/css-reset';
import './App.css';

import Page, { Grid, GridColumn } from '@atlaskit/page';
import React, { Component } from 'react';

import { FeatureEdit }  from '@hindsightsoftware/ui-codemirror-module'
import Footer from './components/footer'
import Header from './components/header'

class App extends Component {
  render() {
    return (
      <Page>
        <Header />
        <div className="App">
          <Grid>
            <GridColumn medium={1} />
            <GridColumn medium={10}>
              <h1>Cucumber Linter</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse purus ex, iaculis a pretium non, sagittis vitae nibh.
              Donec id efficitur turpis, in dignissim nunc.
              Proin felis dui, blandit eu feugiat ac, aliquet eget sapien.
            </p>
              <div style={{'marginBottom':'60px'}}>
                <FeatureEdit />
              </div>
            </GridColumn>
            <GridColumn medium={1} />
          </Grid>
        </div>
        <Footer />
      </Page>
    );
  }
}

export default App;

import '@atlaskit/css-reset';

import { Grid, GridColumn } from '@atlaskit/page';
import React, { Component } from 'react';

import logo from '../images/hindsightLogo.png'
import styles from './header.module.css'

class Header extends Component {
  
  render() {  
    return (
        <div className={styles.header}>
          <Grid>
            <GridColumn medium={2}>
              <img src={logo} alt="Logo" className={styles.headerLogo}/>
            </GridColumn>
            <GridColumn medium={6}/>
            <GridColumn medium={4}>
                <a href="https://www.hindsightsoftware.com/behave-pro">BEHAVE PRO</a>
                <a href="https://www.hindsightsoftware.com/about-bdd">ABOUT BDD</a>
                <a href="https://www.hindsightsoftware.com/hindsight-insights-blog">BLOG</a>
            </GridColumn>
          </Grid>
        </div>
    );
  }
}

export default Header;
